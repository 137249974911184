import React from 'react';
import './Countdown.css';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface CountdownProps {
    fetchHospitals: Function
}

export default function Countdown(props: CountdownProps) {
    const [timer, setTimer] = React.useState(60);
    const fetchHospitals = props.fetchHospitals;

    // On the component mounts, start an interval for the countdown clock
    React.useEffect(() => {
        // Start up the timer to run every second
        const interval = setInterval(() => {
            // If the timer hits 0, then refresh the hospitals
            if (timer === 0) {
                fetchHospitals();
            }
            
            // Calculate the new value and set it
            setTimer(() => {
                return timer === 0 ? 60 : timer - 1;
            });
        }, 1000);

        // Once the component is ready to unmount, clear the interval
        return () => {
            clearInterval(interval);
        };
    }, [timer, fetchHospitals]);

    return (
        <CircularProgressbar value={timer} maxValue={60} text={`0:${(timer < 10 ? "0" + timer : timer)}`} />
    );
}