

/**
 * Humanizes the date time
 * @param {string} strDate the date or datetime
 * @param {string} [format] regional locales
 * @returns string
 */
export function formatDate (strDate: string, format: string, hrFormat: boolean) {
    if (!format) {
      format = 'en-US'
    }
  
    hrFormat = hrFormat === undefined ? false : true;
  
    // If a date isn't passed in, then return what was passed in
    if (strDate === '' || strDate === null || strDate === undefined) {
      return strDate
    }
  
    // is the time zero or if there is just the date
    if (strDate.length === 10) {
      return new Intl.DateTimeFormat(format, { year: 'numeric', month: '2-digit', day: '2-digit' }).format(Date.parse(strDate)).replace(/, /g, ' ')
    }
  
    return new Intl.DateTimeFormat(format, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: hrFormat }).format(Date.parse(strDate)).replace(/, /g, ' ')
}