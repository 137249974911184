import React from 'react';
import { Button, Card, CardBody, CardFooter, Col, Collapse, Container, Input, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';
import './Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../utils';
import Countdown from './Countdown/Countdown';
import Maintenance from './Maintenance';
import logo from '../assets/logo.png';

interface Diversion {
    name: string,
    color: string,
    status_reason: string,
    updated_on_local: string
}

interface Nedoc {
    description: string,
    color: string,
    created_on_clientTime: string
}

interface Specialty {
    name: string,
    color: string,
    changed_on: string,
    availability_status: string
}

interface Hospital {
    agencyName: string,
    region: string,
    diversion: Diversion,
    nedoc: Nedoc,
    specialties: Array<Specialty>,
    county: string
}

interface Search {
    name: string,
    county: string,
    region: string,
    diversion: string,
    type: string,
    sort: string
}

export default function Dashboard () {
    const [hospitals, setHospitals] = React.useState([]);
    const [filteredHospitals, setFilteredHospitals] = React.useState([]);
    const [regions, setRegions] = React.useState(['']);
    const [diversions, setDiversions] = React.useState(['']);
    const [specialties, setSpecialties] = React.useState(['']);
    const [counties, setCounties] = React.useState(['']);
    const [searchbar, setSearchbar] = React.useState('');
    const [search, setSearch] = React.useState({name: "", county: "", region: "", diversion: "", type: "", sort: ""});
    const [displayMaintenancePage, setDisplayMaintenancePage] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [leftPage, setLeftPage] = React.useState(0);
    const [displaySpecialty, setDisplaySpecialty] = React.useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
    const mounted = React.useRef(false);
    const sort = ['County', 'Diversion', 'Diversion Updated', 'Name', 'NEDOCS', 'NEDOCS Updated', 'Region'];

    /**
     * Fetch all of the active hospitals on the RB site
     */
    async function fetchHospitals () {
        const response = await fetch('https://georgiarcc.imagetrendresourcebridge.com/ws/ResourceBridgeAPI/api/Agencies',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const hospitalsResponse = await response.json();

        // If the response is a success and hospitals were returned, then load everything else load the maintenance page.
        if (response.status === 200 && hospitalsResponse.results !== undefined) {
            let newHospitals = hospitalsResponse.results.filter((hosp: Hospital) => hosp.region.length === 1).sort(
                (x: Hospital, y: Hospital) => {
                    if (search.sort === "County") {
                        return x.county.localeCompare(y.county);
                    } else if (search.sort === "Diversion") {
                        // Handle Agencies with no Diversion
                        if (x.diversion.name === undefined) {
                            return 1;
                        } else if (y.diversion.name === undefined) {
                            return -1;
                        }
                        return x.diversion.name.localeCompare(y.diversion.name);
                    } else if (search.sort === "Diversion Updated") {
                        // Handle Agencies with no Diversion
                        if (x.diversion.updated_on_local === undefined) {
                            return 1;
                        } else if (y.diversion.updated_on_local === undefined) {
                            return -1;
                        }
    
                        let date1 = new Date(x.diversion.updated_on_local ).valueOf();
                        let date2 = new Date(y.diversion.updated_on_local ).valueOf();
                        return date2 - date1;
                    } else if (search.sort === "NEDOCS") {
                        // Handle Agencies with no NEDOCS
                        if (x.nedoc.description === undefined) {
                            return 1;
                        } else if (y.nedoc.description === undefined) {
                            return -1;
                        }
                        return x.nedoc.description.localeCompare(y.nedoc.description);
                    } else if (search.sort === "NEDOCS Updated") {
                        // Handle Agencies with no NEDOCS
                        if (x.nedoc.created_on_clientTime === undefined) {
                            return 1;
                        } else if (y.nedoc.created_on_clientTime === undefined) {
                            return -1;
                        }
    
                        let date1 = new Date(x.nedoc.created_on_clientTime ).valueOf();
                        let date2 = new Date(y.nedoc.created_on_clientTime ).valueOf();
                        return date2 - date1;
                    } else if (search.sort === "Region") {
                        return x.region.localeCompare(y.region);
                    } else {
                        return x.agencyName.localeCompare(y.agencyName);
                    }
                }
            );

            // Get the region options
            let newRegions: Array<string> = [];
            newHospitals.forEach((hosp: Hospital) => {
                if(newRegions.find(elem => elem === hosp.region) === undefined) {
                    newRegions.push(hosp.region);
                }
            })
            newRegions.sort((x, y) => x.localeCompare(y));

            // Get the diversion options
            let newDiversions: Array<string> = [];
            newHospitals.forEach((hosp: Hospital) => {
                if(Object.keys(hosp.diversion).length > 0 && newDiversions.find(elem => elem === hosp.diversion.name) === undefined) {
                    newDiversions.push(hosp.diversion.name);
                }
            })
            newDiversions.sort((x, y) => x.localeCompare(y));

            // Get the specialty options
            let newSpecialties: Array<string> = [];
            newHospitals.forEach((hosp: Hospital) => {
                hosp.specialties.forEach((spec) => {
                    if (newSpecialties.find(elem => elem === spec.name) === undefined) {
                        newSpecialties.push(spec.name);
                    }
                })
            })
            newSpecialties.sort((x, y) => x.localeCompare(y));

            // Get the county options
            let newCounties: Array<string> = [];
            newHospitals.forEach((hosp: Hospital) => {
                if(hosp.county !== undefined && hosp.county !== "" && newCounties.find(elem => elem === hosp.county) === undefined) {
                    newCounties.push(hosp.county);
                }
            })
            newCounties.sort((x, y) => x.localeCompare(y));

            // Get the filtered hospitals
            let filteredHospitals = newHospitals.filter((element: Hospital) => 
                element.agencyName.toLowerCase().includes(search.name.toLowerCase(), 0) &&
                (search.region === "" || element.region.toLowerCase() === search.region.toLowerCase()) &&
                (search.diversion === "" || (Object.keys(element.diversion).length > 0 && element.diversion.name.toLowerCase() === search.diversion.toLowerCase())) &&
                (search.type === "" || element.specialties.filter((elem: Specialty) => elem.name === search.type).length > 0) &&
                (search.county === "" || (element.county !== undefined && element.county.toLowerCase() === search.county.toLowerCase()))
            );

            let newPageCount = Math.ceil(filteredHospitals.length / 15.0);
            let newCurrentPage = currentPage > Math.ceil(filteredHospitals.length / 15.0) ? Math.ceil(filteredHospitals.length / 15.0) : currentPage;

            // Determine the left page to be used in the pagination
            let newLeftPage: number;
            if (newPageCount < newCurrentPage + 3) {
                newLeftPage = Math.max(newCurrentPage - 2 - (newCurrentPage + 3 - newPageCount), 0);
            } else {
                newLeftPage = Math.max(newCurrentPage - 2, 0);
            }

            // Update the states
            setHospitals(newHospitals);
            setFilteredHospitals(filteredHospitals);
            setRegions(newRegions);
            setDiversions(newDiversions);
            setSpecialties(newSpecialties);
            setCounties(newCounties);
            setPageCount(newPageCount);
            setCurrentPage(newCurrentPage);
            setLeftPage(newLeftPage);
        } else {
            setDisplayMaintenancePage(true);
        }
    };


    /**
     * Once the component has mounted, fetch the available hospitals
     */
    React.useEffect(() => {
        // Fetch the offices
        fetchHospitals();
    }, []);

    /**
     * Calculate the proper text color based on the background color that is passed in
     */
    function fixTextColors (hexColor: string) {
        // Parse the red, green, and blue from the color
        let r = Number.parseInt(hexColor.substring(0, 2), 16);
        let g = Number.parseInt(hexColor.substring(2, 4), 16);
        let b = Number.parseInt(hexColor.substring(4), 16);

        // Use the background color rgb to calculate what text color should be used and return it
        let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return yiq >= 128 ? "black" : "white";
    }

    /**
     * Change the current page of hospitals that are displayed
     * @param newPage The new page number to be switched to
     */
    function updateCurrentPage (newPage: number) {
        // Determine the left page to be used in the pagination
        let newLeftPage: number;
        if (pageCount < newPage + 3) {
            newLeftPage = Math.max(newPage - 2 - (newPage + 3 - pageCount), 0);
        } else {
            newLeftPage = Math.max(newPage - 2, 0);
        }

        // Change the page of hospitals being displayed and reset the specialty toggles
        setCurrentPage(newPage);
        setLeftPage(newLeftPage);
        setDisplaySpecialty([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
    }

    /**
     * Filter the hospitals on the searched name
     */
    function updateSearch () {
        // Get the search values and update the name filter
        let newSearch = {...search};
        newSearch.name = searchbar;

        // Flip flag to update hospital list
        mounted.current = true;

        // Update the filter criteria and then filter the hospitals on it
        setSearch(newSearch);
    }

    /**
     * Update the searchbars value
     */
    function updateSearchbar (e: React.ChangeEvent<HTMLInputElement>) {
        // Get the inputed value and update the state with it
        let newSearchbar = ((e.nativeEvent as InputEvent).target as HTMLInputElement).value;
        setSearchbar(newSearchbar);
    }

    /**
     * Filter the hospitals based on the selected filter value
     * @param filter The name of the changed filter
     * @param e The filter changed event
     */
    function updateFilters (filter: string, e: React.ChangeEvent<HTMLSelectElement>) {
        // Cast the filter string as a key of Search because this be the joy of working in TypeScript...
        let key = filter as keyof Search;

        // Get the search values and update the name filter
        let newSearch = {...search};
        newSearch[key] = e.target.value;

        // Flip flag to update hospital list
        mounted.current = true;

        // Update the filter criteria and then filter the hospitals on it
        setSearch(newSearch);
    }

    /**
     * Updates the filtered hospitals list based on filter values
     */
    const getFilteredHospitals = React.useCallback(() => {
        // Filter the hospitals on those choices
        let hospitalsFiltered = hospitals.filter((hosp: Hospital) => 
            hosp.agencyName.toLowerCase().includes(search.name.toLowerCase(), 0) &&
            (search.region === "" || hosp.region.toLowerCase() === search.region.toLowerCase()) &&
            (search.diversion === "" || (Object.keys(hosp.diversion).length > 0 && hosp.diversion.name.toLowerCase() === search.diversion.toLowerCase())) &&
            (search.type === "" || hosp.specialties.filter((elem) => elem.name === search.type).length > 0) &&
            (search.county === "" || (hosp.county !== undefined && hosp.county.toLowerCase() === search.county.toLowerCase()))
        ).sort((x: any, y: any) => {
            if (search.sort === "County") {
                return x.county.localeCompare(y.county);
            } else if (search.sort === "Diversion") {
                // Handle Agencies with no Diversion
                if (x.diversion.name === undefined) {
                    return 1;
                } else if (y.diversion.name === undefined) {
                    return -1;
                }
                return x.diversion.name.localeCompare(y.diversion.name);
            } else if (search.sort === "Diversion Updated") {
                // Handle Agencies with no Diversion
                if (x.diversion.updated_on_local === undefined) {
                    return 1;
                } else if (y.diversion.updated_on_local === undefined) {
                    return -1;
                }

                let date1 = new Date(x.diversion.updated_on_local ).valueOf();
                let date2 = new Date(y.diversion.updated_on_local ).valueOf();
                return date2 - date1;
            } else if (search.sort === "NEDOCS") {
                // Handle Agencies with no NEDOCS
                if (x.nedoc.description === undefined) {
                    return 1;
                } else if (y.nedoc.description === undefined) {
                    return -1;
                }
                return x.nedoc.description.localeCompare(y.nedoc.description);
            } else if (search.sort === "NEDOCS Updated") {
                // Handle Agencies with no NEDOCS
                if (x.nedoc.created_on_clientTime === undefined) {
                    return 1;
                } else if (y.nedoc.created_on_clientTime === undefined) {
                    return -1;
                }

                let date1 = new Date(x.nedoc.created_on_clientTime ).valueOf();
                let date2 = new Date(y.nedoc.created_on_clientTime ).valueOf();
                return date2 - date1;
            } else if (search.sort === "Region") {
                return x.region.localeCompare(y.region);
            } else {
                return x.agencyName.localeCompare(y.agencyName);
            }
        });

        let newPageCount = Math.ceil(hospitalsFiltered.length / 15.0);

        // Update the displayed hospitals, page count, and reset the specialty toggles
        setFilteredHospitals(hospitalsFiltered);
        setPageCount(newPageCount);
        setCurrentPage(0);
        setLeftPage(0);
        setDisplaySpecialty([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
    }, [hospitals, search]);

    /**
     * Toggle a hospital specialties
     * @param index The index of the record to toggle the specialties for
     */
    function updateSpecialtyDisplay(index: number) {
        // Get the value at the index, toggle it, and update the state
        let newDisplaySpecialty = [...displaySpecialty];
        newDisplaySpecialty[index] = !newDisplaySpecialty[index];
        setDisplaySpecialty(newDisplaySpecialty);
    }

    /**
     * Reset all the filters
     */
    function resetFilters () {
        // Flip flag to update hospital list
        mounted.current = true;

        // Clear the search and searchbar states
        setSearchbar('');
        setSearch({name: "", county: "", region: "", diversion: "", type: "", sort: ""});
    }

    /**
     * Once the search has been updated, apply the new filters on the displayed hospitals.
     */
    React.useEffect(() => {
        // When the search filters are changed, rerun the hospital filtering
        if (mounted.current) {
            mounted.current = false;
            getFilteredHospitals();
            
        }
    }, [search, getFilteredHospitals]);

    return (
        <div>
            <div className="banner">
                <p><b>If you have a medical emergency and need assistance, please DIAL 911.</b></p>
                <p>The Georgia Coordinating Center (GCC) is ONLY for use by EMS personnel and hospitals.</p>
            </div>
            <Container className="logo-container d-flex">
                <img src={logo} alt="Georgia Coordinating Center" title="Georgia Coordinating Center" />
                <div className="d-flex">
                    <Button className="login align-middle" >
                        <a href="https://georgiarcc.imagetrendresourcebridge.com">
                            <FontAwesomeIcon icon={faRightToBracket} /> Login
                        </a>
                    </Button>
                </div>
            </Container>
            { displayMaintenancePage ? <Maintenance /> :
                <div>
                    <Container className="filters-container">
                        <Row className="gy-2 w-100" md="3" lg="6" xl="6">
                            <Col lg="4" xl="4" style={{ display: 'flex' }}>
                                <Input type="search" id="search-filter" className="search form-control form-control-sm rounded-pill" title="Search on hospital name" autoComplete="off" placeholder="Search" onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateSearchbar(e)} value={searchbar} />
                                <Button className="search float-end btn-sm rounded-pill ms-2" title="Apply Search" onClick={(e) => updateSearch()}>
                                    Search
                                </Button>
                                <Button className="reset float-end btn-sm rounded-pill ms-2" title="Reset Filters" onClick={(e) => resetFilters()}>
                                    Reset
                                </Button>
                            </Col>
                            <Col>
                                <select id="county-filter" className="form-select form-select-sm rounded-pill" title="Filter on counties" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateFilters('county', e)} value={search.county}>
                                    <>
                                        <option value="">County</option>
                                        {counties.map((elem, index) =>
                                            <option value={elem} key={index}>{elem}</option>
                                        )}
                                    </>
                                </select>
                            </Col>
                            <Col lg="1" xl="1">
                                <select id="region-filter" className="form-select form-select-sm rounded-pill" title="Filter on regions" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateFilters('region', e)} value={search.region}>
                                    <>
                                        <option value="">Region</option>
                                        {regions.map((elem, index) =>
                                            <option value={elem} key={index}>{elem}</option>
                                        )}
                                    </>
                                </select>
                            </Col>
                            <Col>
                                <select id="diversion-filter" className="form-select form-select-sm rounded-pill" title="Filter on diversion" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateFilters('diversion', e)}  value={search.diversion}>
                                    <>
                                    <option value="">Diversion</option>
                                        {diversions.map((elem, index) =>
                                            <option value={elem} key={index}>{elem}</option>
                                        )}
                                    </>
                                </select>
                            </Col>
                            <Col>
                                <select id="type-filter" className="form-select form-select-sm rounded-pill" title="Filter on specialties" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateFilters('type', e)} value={search.type}>
                                    <>
                                        <option value="">Type</option>
                                        {specialties.map((elem, index) =>
                                            <option value={elem} key={index}>{elem}</option>
                                        )}
                                    </>
                                </select>
                            </Col>
                            <Col lg="1" xl="1">
                                <select id="sort-filter" className="form-select form-select-sm rounded-pill" title="Sort Agencies By" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateFilters('sort', e)} value={search.sort}>
                                    <option value="">Sort</option>
                                    {sort.map((elem, index) =>
                                        <option value={elem} key={index}>{elem}</option>
                                    )}
                                </select>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        { filteredHospitals.slice((currentPage * 15), (currentPage * 15 + 15)).map((hosp: Hospital, index) =>
                            <Card key={index} style={{ borderColor: '#b5d4ff' }}>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="w-100">
                                            <Row>
                                                <Col xs="4">
                                                    <div className="d-inline-block">
                                                        <span className="title">Name</span>
                                                        <span className="value">{hosp.agencyName}</span>
                                                    </div>
                                                </Col>
                                                <Col xs="1">
                                                    <div className="d-inline-block">
                                                        <span className="title">Region</span>
                                                        <span className="value">{hosp.region}</span>
                                                    </div>
                                                </Col>
                                                <Col xs="2">
                                                    <div className="d-inline-block">
                                                        <span className="title">County</span>
                                                        <span className="value">{hosp.county === undefined ? "" : hosp.county}</span>
                                                    </div>
                                                </Col>
                                                <Col xs="3">
                                                    <div className="d-inline-block">
                                                        <span className="title">Diversion</span>
                                                        <span>
                                                            { Object.keys(hosp.diversion).length > 0 &&
                                                                <span className="badge rounded-pill" style={{backgroundColor: hosp.diversion.color, color: fixTextColors(hosp.diversion.color.replace("#", ""))}} title={hosp.diversion.status_reason}>
                                                                    {hosp.diversion.name}
                                                                </span>
                                                            }
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col xs="2">
                                                    <div className='d-inline-block'>
                                                        <span className="title">Diversion Updated</span>
                                                        <span>{formatDate(hosp.diversion.updated_on_local, 'en-US', false)}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="d-flex" style={{ alignItems: 'center', width: '28px' }}>
                                            { hosp.specialties.length > 0 &&
                                                <Button className="toggle-button rounded-circle" title={displaySpecialty[index] ? "Hide Specialties" : "Show Specialties"} onClick={() => updateSpecialtyDisplay(index)}>
                                                    { displaySpecialty[index] ?
                                                        <FontAwesomeIcon id={"togglerMinus" + index} icon={faMinus} /> :
                                                        <FontAwesomeIcon id={"togglerPlus" + index} icon={faPlus} />
                                                    }
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <Row style={{ marginLeft: '1rem' }}>
                                        <Col className="pt-2 pb-2" xs="4">
                                            <span className="title">NEDOCS</span>
                                            <span>
                                                { Object.keys(hosp.nedoc).length > 0 &&
                                                    <span className="badge rounded-pill" style={{backgroundColor: hosp.nedoc.color.includes("#", 0) ? hosp.nedoc.color : "#" + hosp.nedoc.color, color: fixTextColors(hosp.nedoc.color.replace("#", ""))}}>
                                                        {hosp.nedoc.description}
                                                    </span>
                                                }
                                            </span>
                                        </Col>
                                        <Col className="pt-2 pb-2" xs="8" style={{ paddingLeft: '0' }}>
                                            <span className="title">NEDOCS Updated</span>
                                            <span>{formatDate(hosp.nedoc.created_on_clientTime, 'en-US', false)}</span>
                                        </Col>
                                    </Row>
                                    { hosp.specialties.length > 0 &&
                                        <Collapse isOpen={displaySpecialty[index]}>
                                            <div style={{ borderTop: '1px solid #b5d4ff' }}>
                                                { Array.from({ length: Math.ceil(hosp.specialties.length / 2.0) }, (_, index) =>
                                                    <Row className="row-group" key={index} xs="1" sm="1" md="2">
                                                        <Col className="col-group" style={{ backgroundColor: index % 2 === 0 ? '' : '#e3eeff', padding: '5px 0 5px 0' }}>
                                                            <Row>
                                                                <Col>
                                                                    {hosp.specialties[index * 2].name}
                                                                </Col>
                                                                <Col className="text-center" xs="3">
                                                                    <div className="badge rounded-pill text-wrap"  style={{width: '90%', color: fixTextColors(hosp.specialties[index * 2].color.replace("#", "")), backgroundColor: hosp.specialties[index * 2].color.includes("#", 0) ? hosp.specialties[index * 2].color : "#" + hosp.specialties[index * 2].color }}>
                                                                        {hosp.specialties[index * 2].availability_status}
                                                                    </div>
                                                                </Col>
                                                                <Col className="text-end">
                                                                    {formatDate(hosp.specialties[index * 2].changed_on, 'en-US', false)}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className="col-group" style={{ backgroundColor: index % 2 === 0 ? '#e3eeff' : '', padding: '5px 0 5px 0' }}>
                                                            {hosp.specialties.length > index * 2 + 1 &&
                                                                <Row>
                                                                    <Col>
                                                                        {hosp.specialties[index * 2 + 1].name}
                                                                    </Col>
                                                                    <Col className="text-center" xs="3">
                                                                        <div className="badge rounded-pill text-wrap" style={{ width: '90%', color: fixTextColors(hosp.specialties[index * 2 + 1].color.replace("#", "")), backgroundColor: hosp.specialties[index * 2 + 1].color.includes("#", 0) ? hosp.specialties[index * 2 + 1].color : "#" + hosp.specialties[index * 2 + 1].color }}>
                                                                            {hosp.specialties[index * 2 + 1].availability_status}
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="text-end">
                                                                        {formatDate(hosp.specialties[index * 2 + 1].changed_on, 'en-US', false)}
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </Col>
                                                    </Row>
                                                )}
                                            </div>
                                        </Collapse>
                                    }
                                </CardFooter>
                            </Card>
                            )
                        }
                    </Container>
                    { pageCount > 1 &&
                        <Container>
                            <Pagination size="lg">
                                <PaginationItem onClick={(e) => updateCurrentPage(0)}>
                                    <PaginationLink
                                    first
                                    href=""
                                    title="First"
                                    />
                                </PaginationItem>
                                <PaginationItem onClick={(e) => updateCurrentPage(Math.max(currentPage - 1, 0))}>
                                    <PaginationLink
                                    href=""
                                    previous
                                    title="Previous"
                                    />
                                </PaginationItem>
                                {Array.from({ length: Math.min(pageCount, 5) }, (_, index) =>
                                    <PaginationItem key={index} active={currentPage === leftPage + index} onClick={(e) => updateCurrentPage(leftPage + index)}>
                                        <PaginationLink href="">
                                            {leftPage + index + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                )}
                                <PaginationItem onClick={(e) => updateCurrentPage(Math.min(currentPage + 1, pageCount - 1))}>
                                    <PaginationLink
                                    href=""
                                    next
                                    title="Next"
                                    />
                                </PaginationItem>
                                <PaginationItem onClick={(e) => updateCurrentPage(pageCount - 1)}>
                                    <PaginationLink
                                    href=""
                                    last
                                    title="Last"
                                    />
                                </PaginationItem>
                            </Pagination>
                        </Container>
                    }
                    <div style={{height: '75px'}}>
                        <div className="footer">
                            <div>
                                <span>&#169; 2023 ImageTrend, LLC</span>
                            </div>
                        </div>
                    </div>
                    <div aria-live="polite" aria-atomic="true">
                        <div className="countdown-container">
                            <Countdown fetchHospitals={ fetchHospitals } />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
