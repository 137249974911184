import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

export default function Maintenance() {
    return (
        <div style={{textAlign: 'center'}}>
            <FontAwesomeIcon icon={faTriangleExclamation} color="yellow" style={{ fontSize: '150px' }} />
            <h1>Website Under Maintenance</h1>
            <p>This website is under maintenance. We should be back shortly. Thank you for your patience.</p>
        </div>
    );
}